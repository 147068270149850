import ReadMore from "@components/ReadMore/ReadMore"
import Animation from "@components/elements/Animation"
import { communitiesURL } from "@lib/urls"
import { Link } from "gatsby"
import React from "react"
import "./PropertyAreaguide.scss"
import GGFXImage from "@components/elements/GGFXImage"
import { parseHTML } from "@lib/utils"

const PropertyAreaguide = ({ property, area }) => {
  return (
    <Animation
      animateInType="up"
      className="property-areaguide-wrap"
      id="areaGuidesection"
    >
      {area && (
        <>
          
          <div className="property-areaguide-section">
          <p className="heading d-block d-md-none">{area.title} area guide</p>

            {area.tile_image && (
              <Link
                to={`${communitiesURL + area.slug}/`}
                className="img-section img-zoom"
              >
                 <GGFXImage
          ImageSrc={area.tile_image}
          altText={`${area.title} area guide`}
          imagetransforms={area?.ggfx_results}
          renderer="srcSet"
          imagename="area-guide.tile_image.tile_img"
          strapiID={area?.strapi_id}
        />
                {/* <img
                  src={area.tile_image.url}
                  alt={`${area.title} area guide`}
                /> */}
              </Link>
            )}
            
           
            <div className="content-section">
                <p className="heading d-none d-md-block">{area.title} area guide</p>
                {area.content?.data?.content && (
                 <div class="read-more-wrap read-more"><div class="read-more-section read-more" style={{maxHeight: "150px"}}>
                  {parseHTML(area.content?.data?.content)}</div>
                  <Link to={`${communitiesURL + area.slug}/`} class="read-more-button ">View Area Guide</Link>
                  </div>  )}
              
            
          </div>
            
          </div>
          <div className="divider" />
        </>
      )}
      {property?.extra?.permit_number && (
        <div className="permit-section">
          {property?.qr_code && property?.qr_code?.length > 0 ?
          <div className="qr-code">
            {property?.qr_code && property?.qr_code?.length > 0 && property?.qr_code[0]?.validationUrl ?
            <Link to={property?.qr_code[0]?.validationUrl} target="_blank">
          <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={property?.qr_code && property?.qr_code?.length > 0 ? property?.qr_code[0]?.validationQr: ""} alt="qr code" />
          </Link> :
          <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={property?.qr_code && property?.qr_code?.length > 0 ? property?.qr_code[0]?.validationQr: ""} alt="qr code" />}
          </div> : null}
          <div className="content">
          {property?.qr_code && property?.qr_code?.length > 0 && property?.qr_code[0]?.validationUrl ?
          <Link to={property?.qr_code[0]?.validationUrl} target="_blank">
            <p className="title">DLD Permit Number</p>
            <p className="value">{property?.extra.permit_number}</p>
            </Link> :
            <><p className="title">DLD Permit Number</p>
            <p className="value">{property?.extra.permit_number}</p></>
}
           
          </div>
        </div>
      )}
    </Animation>
  )
}

export default PropertyAreaguide
